import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {
  Grid, Product, Title, PriceTag, Cell, GridContainer
} from './styles'
import { Img, SectionContainer } from '~/utils/styles'

const ProductGrid = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(sort: {fields: [createdAt], order: DESC}) {
          edges {
            node {
              id
              title
              handle
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                    fixed(cropFocus: CENTER, width: 175, height: 175) {
                      ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }      
    `
  )

  return (
    <SectionContainer>
		<GridContainer>
		  <Grid>
			{allShopifyProduct.edges
			  ? allShopifyProduct.edges.slice(0,9).map(({ node: { id, handle, title, images: [firstImage], variants: [firstVariant] } }) => (
				<Cell key={id}>
				  <AniLink 
					cover to={`/product/${handle}/`}
					direction="left"
					bg="pink">
					<Product>
						{firstImage && firstImage.localFile &&
						  (<Img
							fixed={firstImage.localFile.childImageSharp.fixed}
							alt={handle}
						  />)}
					  <Title>{title}</Title>
					  <PriceTag>${firstVariant.price}</PriceTag>
					</Product>
				  </AniLink>
				</Cell>
			  ))
			  : <p>No Products found!</p>}
		  </Grid>
		</GridContainer>
    </SectionContainer>
  )
}

export default ProductGrid
