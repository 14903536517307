import React from 'react'
import {Newsletter, NewsContent, NewsletterTitleContainer,
	NewsletterTitle, NewsletterSubtitle, NewsletterFormContainer,
	NewsletterForm, NewsletterButton, NewsletterInput
} from './styles'
import styled from '@emotion/styled'

export const BotTrap = styled.div`
	display: none;
`
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class NewsletterSection extends React.Component {
    constructor(props){
		super(props);
		this.state = {};
	}
	  handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	  };

	  handleSubmit = e => {
		e.preventDefault();
		const form = e.target;
		fetch("/", {
		  method: "POST",
		  headers: { "Content-Type": "application/x-www-form-urlencoded" },
		  body: encode({
			"form-name": form.getAttribute("name"),
			...this.state
		  })
		})
		  .then(() => alert("Thank you!"))
		  .catch(error => alert(error));
	  };
	render(){
		return (
			<Newsletter>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 offset-lg-2">
							<NewsContent className={`text-center`}>
								<NewsletterTitleContainer>
									<NewsletterTitle>subscribe to our email list</NewsletterTitle>
									<NewsletterSubtitle>Get news and discounts by subscribing. We won't spam, we promise!</NewsletterSubtitle>
								</NewsletterTitleContainer>
								<NewsletterFormContainer>
									<NewsletterForm className="newsletter_form" 
										name="newsletter" 
										method="POST"
										data-netlify="true"
										netlify-honeypot="bot-field"
										onSubmit={this.handleSubmit}
									>
										<BotTrap>
											<label>Don’t fill this out if you're human: <input name="bot-field" onChange={this.handleChange} /></label>
										</BotTrap>
										<NewsletterInput type="email" name="email" placeholder="your e-mail here" required="required" onChange={this.handleChange}/>
										<NewsletterButton type="submit">submit</NewsletterButton>
									</NewsletterForm>
								</NewsletterFormContainer>
							</NewsContent>
						</div>
					</div>
				</div>
			</Newsletter>
		)
	}
}