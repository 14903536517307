import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import {Boxes, Box, BoxImage, BoxTitle, 
    BoxCol, BoxFlex
} from './styles'
import {SectionContainer} from '~/utils/styles'

const HomeContentSecond = () => {
   const elements = useStaticQuery(graphql`
    {
        collections: allShopifyCollection {
          edges {
            node {
              title
              handle
              image {
                localFile {
                  childImageSharp {
                    fluid (maxHeight: 720, maxWidth: 1280) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
    }      
    `)

    return (
        <Boxes>
            <SectionContainer>
                <BoxFlex>
                    {elements.collections.edges ?
                        elements.collections.edges.map(({node}) => (
                        <BoxCol>
                            <AniLink cover
                                to={`/products/c/${node.handle}`}
                                direction="left"
                                bg="pink">
                                <Box>
                                    <BoxImage>
                                        <Img 
                                            fluid={node.image.localFile.childImageSharp.fluid} 
                                            alt={`category ${node.title}`} 
                                        />
                                    </BoxImage>
                                    <BoxTitle className={`trans_200`}>
                                        <p>{node.title}</p>
                                    </BoxTitle>
                                </Box>
                            </AniLink>
                        </BoxCol>
                        )
                    ) : <p>There are no collections to show</p>
                    }
                </BoxFlex>
            </SectionContainer>
        </Boxes>
    )
}

export default HomeContentSecond

