import React from 'react'
import { graphql } from 'gatsby'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from 'gatsby-image'
import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'
import HomeContentSecond from '~/components/HomeContentSecond'
import NewsletterSection from '~/components/NewsletterSection'
import {Banner, BannerContainer, ProductsContainer, Wrapper, 
    CategoryTitle, ProductTitle
} 
from '../utils/styles'

const IndexPage = ({data}) => {
  return (
    <>
	<SEO title="Home" keywords={[`bags`, `ecommerce`, `shopify`]} />
      <Banner>
        <BannerContainer>
            <Img 
              className={`background_img`}
              fluid={data.source.childImageSharp.fluid}
              alt="Photo by Icons8 Team on Unsplash" 
            />
        </BannerContainer>
      </Banner>      
      <Wrapper>
          <CategoryTitle>
            <h1>Featured Selections</h1>
          </CategoryTitle>
        <HomeContentSecond />
          <ProductTitle>
            <h1>Products</h1>
          </ProductTitle>
        <ProductsContainer>
          <ProductGrid />
          <p><AniLink 
            cover to='/products'
            bg='pink'
            duration={1.5}
          >See more...</AniLink></p>
        </ProductsContainer>
		    <NewsletterSection />
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query {
    source: file(relativePath: {eq: "bg/slider8.png"}, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        fluid (quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
