import styled from '@emotion/styled'
import {breakpoints} from '~/utils/styles'

export const Boxes = styled.div`
    width: 100%;
    background: #fff;
    padding-top: 100px;
    z-index: 1;
    margin-bottom: 70px;

	@media (max-width: ${breakpoints.m}px){
        margin-bottom: 80px;
        padding-top: 10px;
	}
`
export const BoxFlex = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
`
export const Box = styled.div`
    width: 100%;
`
export const BoxImage = styled.div`
    width: 100%;
    & img {
        max-width: 100%;
    }
`
export const BoxTitle = styled.div`
    position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	width: 237px;
	height: 40px;
	background: #FFFFFF;
    text-align: center;
	opacity: 0.8;
    &:hover {
        background: pink;
		opacity: 1;
    }
    & p {
        display: block;
        line-height: 40px;
        font-size: 14px;
        font-weight: 700;
        color: #1a1a1a;
        text-transform: uppercase;
    }
`
export const BoxCol = styled.div`
    flex-basis: 33%;
    padding: 10px;

    @media (max-width: ${breakpoints.m}px){
		flex-basis: 100%;
	}
`