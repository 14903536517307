import styled from '@emotion/styled'
import newsletter from '../../images/newsletter.jpg'

export const Newsletter = styled.div`
	width: 100%;
	padding-top: 95px;
	padding-bottom: 81px;
	background-image: url(${newsletter});

	  /* Set a specific height */
	  height: 500px;

	  /* Create the parallax scrolling effect */
	  background-attachment: fixed;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;
`
export const NewsContent = styled.div`

`
export const NewsletterTitleContainer = styled.div`

`
export const NewsletterTitle = styled.div`
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 1.2;
	text-transform: uppercase;
}
`
export const NewsletterSubtitle = styled.div`
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #FFFFFF;
	margin-top: 14px;
	
`
export const NewsletterFormContainer = styled.div`
	margin-top: 94px;
`
export const NewsletterForm = styled.form`
	width: 100%;
`
export const NewsletterButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	width: 138px;
	height: 45px;
	background: pink;
	color: #1a1a1a;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	border: none;
	outline: none;
	cursor: pointer;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
`
export const NewsletterInput = styled.input`
	width: 100%;
	height: 45px;
	background: transparent;
	border: none;
	outline: none;
	border-bottom: solid 2px #FFFFFF;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
	&:hover, &:focus {
		border-bottom: solid 2px pink;
	}
	&::input-placeholder {
		font-size: 12px !important;
		font-weight: 400 !important;
		font-style: italic;
		color: #FFFFFF !important;
	}
`